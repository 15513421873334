<template>
    // loop leaderboard and display in table
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Avatar</th>
          <th scope="col">Rank</th>
          <th scope="col">Code</th>
          <th scope="col">Referrals</th>
          <th scope="col">🍞</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in state.leaderboard" :key="index">
          <th scope="row"><img alt="Avatar" v-bind:src="item.avatar" style="width: 50px"></th>
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ item.code }}</td>
          <td>{{ item.count }}</td>
          <td> ${{ 0.25 * item.count }}</td>
        </tr>
      </tbody>
    </table>
</template>
  
<script setup>
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const state = reactive({
  leaderboard: []
})

const router = useRouter()

onMounted(() => {
  getLeaderboardData()
});

async function getLeaderboardData() { 

  await router.isReady()

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://stark-earth-92989.herokuapp.com/referral/leaderboard`,
    headers: { }
  };
  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    state.leaderboard = response.data.sort((a, b) => b.count - a.count)
    console.log(state.leaderboard)
  })
  .catch((error) => {
    console.log(error);
  });
}

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
  