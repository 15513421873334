<template>
  <div id="nav">
    <a href="https://bosslife.app" target="_blank" rel="noopener noreferrer">
      <img alt="Bosslife" id="logo" src="./assets/bosslife-logo.svg" />
    </a>
  </div>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  
  }
}
</script>

<style>
#app {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url('./assets/background@3x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#nav {
  display: flex;
  justify-content: flex-start;
  padding-top: 60px;
  max-width: 80%;
  margin: 0 auto;
}
#nav #logo {
  max-width: 330px;
  max-height: 145px;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  #nav {
    margin-left: 12px;
  }
}
</style>
