
<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-1">
        <!---->
      </div>
      <div class="col-sm-6 col-md-7 col-lg-6">
        <div class="referree-container">
          <p v-if="state.referrerEmail">You were referred by <strong>{{ state.referrerEmail }}</strong></p>
        </div>
        <h1 class="title">
          Own the<br/>games you play
        </h1>
        <p><strong>Get on the Bosslife waitlist!</strong> The new way to invest.</p>
        <form v-if="!state.showSuccess" @submit.prevent="newReferral">
          <div class="form-group">
            <label for="email-address" class="tl">Email address</label>
            <input type="email" v-model="state.email" class="form-control" id="email-address" aria-describedby="emailHelp" placeholder="Enter email"  @input="event => text = event.target.value">
          </div>
          <br />
          <div class="form-group" readonly="readonly" hidden>
            <label for="referral-code" class="tl">Referral Code</label>
            <input type="text" class="form-control" id="referral-code" placeholder="Referral Code" v-model="state.referralCode">
          </div>
          <br />
          <button type="submit" v-bind:disabled="state.email === ''" class="submit-cta"><img alt="I'm in!" src="../assets/cta.svg" /></button>
        </form>
        <p v-if="state.showSuccess" class="success-message">
          Thanks! You will hear back from us soon!
        </p>
      </div>
      <div class="col-sm-6 col-md-5 col-lg-4" >
        <div id="avatar">
          <img alt="" src="../assets/avatar.svg" style="width: 100%; height: auto;" />
          <img alt="" v-if="state.avatar" v-bind:src="state.avatar" class="avatar">
        </div>
      </div>
      <div class="col-lg-1">
        <!---->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios'

const state = reactive({
  email: '',
  referralCode: '',
  referrerEmail: '',
  avatar: '',
  showSuccess: false,
  showFailure: false,
  errorMessage: ''
})
const router = useRouter()
const route = useRoute()

//just to demonstrate
console.log(route.query)
// If it is a page refresh it will print null.
// The router wont be ready if its a page refresh. In that case the query wont be available.
// The router will be ready if its navigation from another page in that case the query params will be available

onMounted(() => {
  getUrlQueryParams()
  getReferrerData()
});

async function getUrlQueryParams() {    
  //router is async so we wait for it to be ready
  await router.isReady()
  //once its ready we can access the query params
  console.log(route.query)
  console.log(route.params)

  state.referralCode = route.params.code
  console.log(`state.referralCode: ${state.referralCode}`)
}

async function getReferrerData() { 

  await router.isReady()

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://stark-earth-92989.herokuapp.com/referral/referrer/${state.referralCode}`,
    headers: { }
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    state.avatar = response.data.avatar
    state.referrerEmail = response.data.username
  })
  .catch((error) => {
    console.log(error);
  });
}

async function newReferral() { 

  await router.isReady()

  let data = JSON.stringify({
    "code": state.referralCode,
    "email": state.email, 
    "created_at": new Date().toISOString()
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://stark-earth-92989.herokuapp.com/referral/new',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU0NWUyNDZjNTEwNmExMGQ2MzFiMTA0M2E3MWJiNTllNWJhMGM5NGQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiMjM2YmE3ZGQtNDk4OC00ZjU3LTg5MGMtNjY2ZWZkNDc4YTY5LS1OREUyT0RFNE16ZzFOUT09IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2JvaWZlLWRldiIsImF1ZCI6ImJvaWZlLWRldiIsImF1dGhfdGltZSI6MTY4NjA3NjY2MiwidXNlcl9pZCI6ImJPaFJ0MldFbXRjbVJ5dFF5SmpudFJPVzJZMDMiLCJzdWIiOiJiT2hSdDJXRW10Y21SeXRReUpqbnRST1cyWTAzIiwiaWF0IjoxNjg2MDc2NjYyLCJleHAiOjE2ODYwODAyNjIsInBob25lX251bWJlciI6IisxNDE2ODE4Mzg1NSIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsicGhvbmUiOlsiKzE0MTY4MTgzODU1Il19LCJzaWduX2luX3Byb3ZpZGVyIjoicGhvbmUifX0.hKRv9GI4VyaHEhN-WK_rHWWF9utS-l23Zjq_nqR1QSFoLCUeaCUWVqQ9k8Pquyh2TXb4Km1wcFHQBWPB9llh4IOppQYQ9PKn5hwzAUKm6qerQ_SsNsnTQKbaBbp_b4nZLQ4HBEIwo_e6VHrKFPUbbvyO3covyydZ4iCy9W3Ry1T3ZiNg-5EUTX-k8vA9MTnp7ibV0S6I-rtpKDdUk56Iq-4v5D4izscvGepuM5LlpouwIjwzAaurKac7HMOgsCBXFZ5saxEZkY6CtDUOmbRGZ-Vllb2XN4oIawwPATZ0tesqQJ4kyZROuVaTt5UA1N01NVrYV8RFJYQdJNH9ETnfuQ'
    },
    data: data
  };

  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));

      state.showSuccess = true
      cleanUp(state)
    })
    .catch((error) => {
      console.log(error);
      state.errorMessage = error
      state.showFailure = true
      cleanUp(state)
    });
  }

  function cleanUp(state) { 
    state.email = ''
    state.referralCode = ''
  }

</script>

<style scoped>
.referree-container {
  background-color: #4DC0E8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  width: fit-content;
}
.referree-container p {
  margin: 0;
}

.title {
  color: #00BAB3;
  text-align: left;
  font-weight: 700;
  font-size: 60px;
  line-height: 1em;
  margin: 24px 0 16px;
}
.tl {
  float: left; 
  text-align: left;
}

p {
  text-align: left;
}

.submit-cta {
  outline: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.submit-cta:disabled {
  cursor: not-allowed;
}

#avatar {
  position: relative;
}

.avatar {
  position: absolute;
  bottom: 8%;
  left: 31%;
  width: 42%;
}

.success-message {
  color: #773DBD;
  font-weight: 700;
  font-style: italic;
  font-size: 28px;
}

</style>
